.circle-wave-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    // position: relative;
    background: #151413;

}

.icon,
.avatar,
.avatar1,
.avatar2,
.avatar3 {
    width: 60px;
    border-radius: 50%;
    height: 60px;
    padding: 0.2rem;
    background-color: #FFFFFF;
}

.menu-item {
    width: 150px;
    max-height: 100px;
    background-color: darkslateblue;
    color: white;
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    margin-bottom: 4px;

    transform-origin: 0 0;
    transform: translateX(-100px);
    opacity: 0;

    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: ease;
}

.circle {
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // animation: wave 5s infinite ease-in;
    opacity: 1;

    &.central-circle {
        width: 100px;
        height: 100px;
        background-color: #b05b1a;
        z-index: 6;
        animation: waves 2s infinite ease-in-out;

        .icon {
            width: 70%;
            height: 70%;
        }
    }

    &.circle-2 {
        width: 180px;
        height: 180px;
        background-color: #8e4c1a;
        z-index: 5;
        animation: wavess 2s infinite ease-in-out, rotateRight 10s infinite linear;
        animation-direction: reverse;


        .avatar {
            position: absolute;
            top: 0%;
            left: 0%;
            transform: translate(15%, -15%);
            width: 50px;
            height: 50px;
        }
    }

    &.circle-3 {
        width: 260px;
        height: 260px;
        background-color: #693b19;
        z-index: 4;
        animation: wavesss 2s infinite ease-in-out;

        .avatar {
            position: absolute;
            top: 85%;
            left: 70%;
            transform: translate(50%, -50%);
            width: 50px;
            height: 50px;
        }
    }

    &.circle-4 {
        width: 340px;
        height: 340px;
        background-color: #422a15;
        z-index: 3;
        animation: wavessss 2s infinite ease-in-out;

        .avatar {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            width: 50px;
            height: 50px;
        }

        .avatar2 {
            position: absolute;
            top: 30%;
            right: 100%;
            transform: translate(50%, 50%);
            width: 50px;
            height: 50px;
        }

        .avatar1 {
            position: absolute;
            bottom: 0%;
            right: 60%;
            transform: translate(10%, 50%);
            width: 50px;
            height: 50px;
        }

        .avatar3 {
            position: absolute;
            bottom: 90%;
            right: 20%;
            transform: translate(-30%, 0%);
            width: 50px;
            height: 50px;
        }
    }


}

@keyframes wave {

    0%,
    100% {
        opacity: 1;
        transform: scale(1);
    }

    20% {
        opacity: 0.8;
        transform: scale(1.1);
    }

    40% {
        opacity: 0.6;
        // transform: scale(1.2);
        transform: rotate(180deg);
    }

    60% {
        opacity: 0.8;
        transform: scale(1.1);
    }

    80% {
        opacity: 0.6;
        transform: scale(1.2);
    }
}

@keyframes waves {

    0% {
        transform: scale(1);
        opacity: 1;
    }

    // 25% {
    //     transform: scale(1.2);
    //     opacity: 0.6;
    // }

    50% {
        transform: scale(1.5);
        opacity: 0.8;
    }

    // 75% {
    //     transform: scale(1.7);
    //     opacity: 0.6;
    // }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes wavess {

    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes wavesss {

    0% {
        transform: scale(1);
        opacity: 1;
        transform: rotate(0deg);
    }

    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }

    100% {
        // transform: scale(1);
        opacity: 1;
        transform: rotate(360deg);
    }
}

@keyframes wavessss {

    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (max-width:490px) {

    .icon,
    .avatar,
    .avatar1,
    .avatar2,
    .avatar3 {
        width: 50px !important;
        height: 50px !important;
    }

    .circle {
        &.central-circle {
            width: 75px;
            height: 75px;

            .icon {
                width: 55% !important;
                height: 55% !important;
            }
        }

        &.circle-2 {
            width: 140px;
            height: 140px;

            .avatar {
                width: 40px !important;
                height: 40px !important;
            }
        }

        &.circle-3 {
            width: 190px;
            height: 190px;

            .avatar {
                width: 40px !important;
                height: 40px !important;
            }
        }

        &.circle-4 {
            width: 249px;
            height: 249px;

            .avatar {
                width: 40px !important;
                height: 40px !important;
            }

            .avatar2 {
                width: 40px !important;
                height: 40px !important;
            }

            .avatar1 {
                width: 40px !important;
                height: 40px !important;
            }

            .avatar3 {
                width: 40px !important;
                height: 40px !important;
            }
        }


    }
}