nav {
    position: fixed;
    z-index: 99;
    width: 100%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 84%);
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(7.5px);
}

nav .wrapper {
    position: relative;
    width: 1400px;
    // padding: 0px 100px;
    height: 100px;
    line-height: 70px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
 
    img {
        width: 70px;
        position: relative;
        z-index: 9999;
    }
}

.wrapper .logo a {
    color: #f2f2f2;
    font-size: 30px;
    font-weight: 600;
    text-decoration: none;
}

.wrapper .nav-links {
    display: inline-flex;
}

.nav-links li {
    list-style: none;
}

.nav-links li a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 15px;
    transition: all 0.3s ease;
}

.nav-links li a:hover {
    // border-bottom: 2px solid #EE781C;
    text-decoration: none;
    color: #EE781C !important;
}

.nav-links .active a {
    color: #EE781C;
    font-weight: bold;
    border-bottom: 2px solid #EE781C !important;
}

.nav-links .activeMenu {
    color: #EE781C;
    font-weight: bold;
    border-bottom:none;
}

.nav-links .mobile-item {
    display: none;
}

.nav-links li:hover .mega-box {
    transition: all 0.3s ease;
    top: 85px;
    opacity: 1;
    visibility: visible;
}

.mega-box {
    position: absolute;
    left: 0;
    width: 100%;
    top: 80px;
    opacity: 0;
    visibility: hidden;
}

.mega-box .contents {
    display: grid;
    // justify-items: start;
    width: 100%;
    align-items: center;
    padding-bottom: 40px;
    // justify-content: start;
    // justify-content: center;
    box-shadow: 0 1px 2px rgb(0 0 0 / 84%);
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(7.5px);
}
.mega-box .content{
    margin-top: -162px;
    font-size: 20px;
    width: 53%;
    line-height: 43px;
    margin-left: 180px;
    display: block;
}
.mega-box .contents .row {
    display: flex;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
        position: relative;
        // bottom: 26%;
        top: 23%;
        left: 25%;
        line-height: 90px;
    
}
.mega-box .contents .row .active{
    color: #EE781C;
    font-weight: bold; 
}

.contents .row-products {
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    border-right: 3px solid rgba(255, 255, 255, 0.2);
    margin: 40px;
    padding: 45px 40px;
}

.contents .row-service{
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    border-right: 3px solid rgba(255, 255, 255, 0.2);
    margin: 42px;
    padding: 43px;
}

// .products .desktop-item {
//     display: flex;
//     align-items: center;
// }
.contents .head-trust{
    color: white;
    text-align: center;
    font-size: 30px;
    display: block;
}
.contents .head-trust .trust-dot{
    color: #ee781c;
}
.contents .row-item{
    display: block;
}
.submenus{
    display: grid;
    padding-left: 195px;
    grid-template-columns: repeat(3, 1fr);
    gap: 23px;
    justify-content: start;
    align-items: start;
    position: relative;
    bottom: 179px;
    /* margin-top: -103px; */
    /* position: relative; */
    /* top: 25%; */
    /* padding-top: 4rem; */
    line-height: 30px;
    
}

.submenuse .sub-head{
       /* top: -186%; */
       bottom: 100%;
       margin-bottom: 118px;
       gap: 20px;
       display: flex;
       flex-direction: row;
       position: relative;
       left: 93%;

}
.submenus li a {
    text-decoration: none;
    color: #FFFFFF;
}

.nav-links .submenu li a:hover{
    text-decoration: none !important;
    color: #EE781C !important;
}

.submenu{
    display: flex;
    margin-top: 5rem;
}

.products .desktop-item.activeMenu .submenus{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.products.desktop-item.activeMenu.submenu{
    display: flex;
}

.contents .row .mega-link-content {
    margin-left: -40px;
    display: grid;
    align-items: center;
    color: #FFFFFF;
    width: 70%;
    padding-left: 40px;
    // gap: 1px;
    h2 {
        font-size: 30px;
        span {
            color: #EE781C;
        }
    }

    p {
        font-size: 20px;
        margin-bottom: 1rem;
    }
}

.contents .row .mega-links {
    margin-left: -40px;
    display: flex;
    align-items: center;
}

.row .mega-links li {
    margin: 0; 
    padding: 5px 10px;
}

.row .mega-links li a {
    padding: 0 20px;
    color: #FFFFFF; 
    font-size: 17px;
    display: block;
    transition: color 0.3s ease; 
}

.row .mega-links li a:hover {
    color: #EE781C; 
    text-decoration: none;
}

.wrapper .btn {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    display: none;
}

.wrapper .btn.close-btn {
    position: absolute;
    right: 0;
    top: -20px;
}

#menu-btn:checked ~ .nav-links {
    display: block;
}

#close-btn:checked ~ .nav-links {
    display: none;
}

 
@media screen and (max-width: 970px) {
    .wrapper .btn {
        display: block !important;
        right: 22px !important;
        top: 45px !important;
    }
 
    .wrapper .nav-links {
        position: fixed;
        height: 100vh;
        width: 100%;
        max-width: 350px;
        top: 0;
        left: -100%;
        background: #242526;
        display: block;
        padding: 100px 10px 50px 10px;
        line-height: 50px;
        overflow-y: auto;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
        transition: all 0.3s ease;
    }
    .mega-box .content{
        // margin-top: -162px;
        // font-size: 20px;
        // width: 53%;
        // line-height: 43px;
        // margin-left: 180px;
        display: none;
    }
    .contents .head-trust{
        // color: white;
        // text-align: center;
        // font-size: 30px;
        display: none;
    }
    .contents .row-item{
        display: none;
    }
    ::-webkit-scrollbar {
        width: 10px;
    }
 
    ::-webkit-scrollbar-track {
        background: #242526;
    }
 
    ::-webkit-scrollbar-thumb {
        background: #3A3B3C;
    }
 
    #menu-btn:checked~.nav-links {
        left: 0%;
    }
 
    #menu-btn:checked~.btn.menu-btn {
        display: none;
    }
 
    #close-btn:checked~.btn.menu-btn {
        display: block !important;
    }
 
    .nav-links li {
        margin: 15px 10px;
        border-bottom: 2px solid gray;
 
        &:hover {
           
            border-bottom: 2px solid #EE781C !important;
        }
    }
 
    .nav-links li a {
        padding: 0 17px;
        display: block;
        font-size: 16px;
        line-height: 30px;
    }
 
    .nav-links .drop-menu {
        position: static;
        opacity: 1;
        top: 65px;
        visibility: visible;
        padding-left: 20px;
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        box-shadow: none;
        transition: all 0.3s ease;
    }
 
    #showDrop:checked~.drop-menu,
    #showMega:checked~.mega-box {
        max-height: 100%;
    }
 
    .nav-links .desktop-item {
        display: none;
    }
 
    .nav-links .mobile-item {
        display: block;
        color: #f2f2f2;
        font-size: 20px;
        font-weight: 500;
        padding-left: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        border-bottom: 2px solid gray !important;
    }
    .what-we-do-text{
        font-size: 16px;
    }
    .what-we-do .mobile-item.sub-section {
        display: flex;
        gap: 67px;
        justify-content: space-between;
    }
 
    .nav-links .mobile-item:hover {
        border-bottom: 2px solid #EE781C !important;
        border-radius: 0;
    }
 
    .drop-menu li {
        margin: 0;
    }
 
    .drop-menu li a {
        border-radius: 5px;
        font-size: 18px;
    }
 
    .mega-box {
        position: static;
        top: 65px;
        opacity: 1;
        visibility: visible;
        padding: 0 20px;
        max-height: 0px;
        overflow: hidden;
        transition: all 0.3s ease;
    }
 
    .mega-box .contents {
        box-shadow: none;
        flex-direction: column;
        padding: 20px;
        display: block;
    }
 
    .mega-box .contents .row {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
        display: grid;
        justify-items: center;
        margin-left: -59px;
    }
 
    .mega-box .contents .row:nth-child(1) {
        display: none;
    }
 
    .mega-box .contents .row:nth-child(2) {
        border-top: 0px;
    }
 
    .mega-box .products {
        .mobile-item {
            padding-left: 0;
            display: block;
            width: -webkit-fill-available;
        }
 
        .mobile-item.sub-section {
            display: flex;
            justify-content: space-between;
        }
 
        .show li a {
            padding: 0 !important;
        }
    }
 
    .contents .row .mega-links {
        padding-left: 15px;
    }
 
    .row .mega-links li {
        margin: 0;
    }
 
    .contents .row header {
        font-size: 19px;
        border-right: none;
        margin: 0;
        padding: 10px !important;
    }
}
 
@media screen and (max-width: 490px) {
    .wrapper .nav-links {
        max-width: 100%;
    }
}
 
nav input {
    display: none;
}
 
.body-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 0 30px;
}
 
.body-text div {
    font-size: 45px;
    font-weight: 600;
}
@media screen and (min-width: 971px) and (max-width: 1200px) {
    nav {
        position: fixed;
        z-index: 99;
        width: 100%;
        box-shadow: 0 1px 2px rgb(0 0 0 / 84%);
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(7.5px);
    }
    
    nav .wrapper {
        position: relative;
        max-width: 100%;
        padding: 0px 30px;
        height: 100px;
        line-height: 70px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
     
        img {
            width: 70px;
            position: relative;
            z-index: 9999;
        }
    }
    
    .wrapper .logo a {
        color: #f2f2f2;
        font-size: 30px;
        font-weight: 600;
        text-decoration: none;
    }
    
    .wrapper .nav-links {
        display: inline-flex;
    }
    
    .nav-links li {
        list-style: none;
    }
    
    .nav-links li a {
        color: #FFFFFF;
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;
        padding: 9px 15px;
        transition: all 0.3s ease;
    }
    
    .nav-links li a:hover {
        // border-bottom: 2px solid #EE781C;
        text-decoration: none;
        color: #EE781C !important;
    }
    
    .nav-links .active a {
        color: #EE781C;
        font-weight: bold;
        border-bottom: none;
    }
    
    .nav-links .activeMenu {
        color: #EE781C;
        font-weight: bold;
        border-bottom: none;
    }
    
    .nav-links .mobile-item {
        display: none;
    }
    
    .nav-links li:hover .mega-box {
        transition: all 0.3s ease;
        top: 85px;
        opacity: 1;
        visibility: visible;
    }
    
    .mega-box {
        position: absolute;
        left: 0;
        width: 100%;
        top: 80px;
        opacity: 0;
        visibility: hidden;
    }
    
    .mega-box .contents {
        display: grid;
        // justify-items: start;
        width: 100%;
        align-items: center;
        padding-bottom: 40px;
        // justify-content: start;
        // justify-content: center;
        box-shadow: 0 1px 2px rgb(0 0 0 / 84%);
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(7.5px);
    }
    .mega-box .content{
        margin-top: -182px;
        font-size: 18px;
        width: 53%;
        line-height: 43px;
        margin-left: 180px;
        display: block;
    }
    .mega-box .contents .row {
        display: flex;
        color: #f2f2f2;
        font-size: 20px;
        font-weight: 500;
            position: relative;
            // bottom: 26%;
            top: 23%;
            left: 25%;
            line-height: 90px;
        
    }
    .mega-box .contents .row .active{
        color: #EE781C;
        font-weight: bold; 
    }
    
    .contents .row-products {
        color: #f2f2f2;
        font-size: 20px;
        font-weight: 500;
        border-right: 3px solid rgba(255, 255, 255, 0.2);
        margin: 40px;
        padding: 45px 40px;
    }
    
    .contents .row-service{
        color: #f2f2f2;
        font-size: 20px;
        font-weight: 500;
        border-right: 3px solid rgba(255, 255, 255, 0.2);
        margin: 42px;
        padding: 43px;
    }
    
    // .products .desktop-item {
    //     display: flex;
    //     align-items: center;
    // }
    .contents .head-trust{
        color: white;
        text-align: center;
        font-size: 30px;
        display: block;
    }
    .contents .head-trust .trust-dot{
        color: #ee781c;
    }
    .contents .row-item{
        display: block;
    }
    .submenus{
        display: grid;
                padding-left: 184px;
                grid-template-columns: repeat(2, 1fr);
                gap: 12px;
                justify-content: start;
                align-items: start;
                position: relative;
                bottom: 200px;
                /* margin-top: -103px; */
                /* position: relative; */
                /* top: 25%; */
                /* padding-top: 4rem; */
                line-height: 30px;
        
    }
    
    .submenuse .sub-head{
           /* top: -186%; */
           bottom: 100%;
           margin-bottom: 118px;
           gap: 20px;
           display: flex;
           flex-direction: row;
           position: relative;
           left: 93%;
    
    }
    .submenus li a {
        text-decoration: none;
        color: #FFFFFF;
    }
    
    .nav-links .submenu li a:hover{
        text-decoration: none !important;
        color: #EE781C !important;
    }
    
    .submenu{
        display: flex;
        margin-top: 5rem;
    }
    
    .products .desktop-item.activeMenu .submenus{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    
    .products.desktop-item.activeMenu.submenu{
        display: flex;
    }
    
    .contents .row .mega-link-content {
        margin-left: -40px;
        display: grid;
        align-items: center;
        color: #FFFFFF;
        width: 70%;
        padding-left: 40px;
        // gap: 1px;
        h2 {
            font-size: 30px;
            span {
                color: #EE781C;
            }
        }
    
        p {
            font-size: 20px;
            margin-bottom: 1rem;
        }
    }
    
    .contents .row .mega-links {
        margin-left: -40px;
        display: flex;
        align-items: center;
    }
    
    .row .mega-links li {
        margin: 0; 
        padding: 5px 10px;
    }
    
    .row .mega-links li a {
        padding: 0 20px;
        color: #FFFFFF; 
        font-size: 17px;
        display: block;
        transition: color 0.3s ease; 
    }
    
    .row .mega-links li a:hover {
        color: #EE781C; 
        text-decoration: none;
    }
    
    .wrapper .btn {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        display: none;
    }
    
    .wrapper .btn.close-btn {
        position: absolute;
        right: 0;
        top: -20px;
    }
    
    #menu-btn:checked ~ .nav-links {
        display: block;
    }
    
    #close-btn:checked ~ .nav-links {
        display: none;
    }
}
@media screen and (max-width: 1400px){
    
    nav .wrapper {
        // position: relative;
        width: 92%;
        // padding: 0px 30px;
        // height: 100px;
        // line-height: 70px;
        // margin: auto;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
    }
}