.banner-section {
    background-image:
        linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 200px),
        url('../../Assets/Home/Banner-Image.webp');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    .banner-content {
        display: flex;
        width: 1440px;
        padding: 20px;
        

        h1 {
            font-size: 3.5rem;
    font-weight: lighter;
    color: white;
    padding-top: 30px;
    position: absolute;
    top: 65%;
    text-align: left;
    padding-left: 40px;
        }

        span {
            color: #ee771cb4;
            font-weight: 600;
        }

    }
}

@media screen and (max-width: 1220px) {
    .banner-section {
        height: 70vh;

        h1 {
            font-size: 2rem !important;
            top: 45% !important;
        }

        p {
            font-size: 1.5rem;
        }

        .banner-content {

            h1 {
                font-size: 0.5rem;
            }

            span {
                color: #ee771cb4;
                font-weight: 600;
            }

        }
    }
}

@media screen and (max-width: 768px) {
    .banner-section {
        height: 55vh;

        h1 {
            font-size: 1.2rem !important;
            top: 36% !important;
        }

        p {
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-width: 490px) {
    .banner-section {
        height: 45vh;

        h1 {
            font-size: 0.9rem !important;
            top: 30% !important;
            width: 58%;
        }

        p {
            font-size: 1.2rem;
        }
    }
}