.client-section {
    background-color: #141414;

    .ourClients-title {
        color: #FFF;
        font-size: 32px;
        margin-bottom: 2rem;
        padding: 1.5rem 0 0 0rem;
    }

    .ourClients-btn {
        background-color: #3a3a3a;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        margin: 0 2.5rem 0 0rem;
        margin-top: 2rem;
    }
}

.ourClients {
    padding: 4rem 0;
    background-image: url("../../Assets//Home/ClientSecBanner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .slick-list {
        overflow: hidden;
    }

    .ourClients-images {
        width: 200px !important;
    }

    .slick-slider {
        padding: 0px 30px;
        background-color: #00000099;
    }

    .slick-initialized .slick-slide {
        // width: 380px !important;
        width:300px !important;
    }

    .slick-track {
        display: flex;
        place-items: center;
        gap: 1rem;
    }

    .slick-prev:before {
        font-size: 35px;
        opacity: 1;
        line-height: 0;
    }

    .slick-next:before {
        font-size: 35px;
        opacity: 1;
        line-height: 0;
    }

    .slick-prev,
    .slick-next {
        display: none !important;
    }
}

@media (max-width: 500px) {
    .ourClients-title {
        font-size: 28px !important;
    }

    .ourClients {
        padding: 0;
    }
}