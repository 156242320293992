.whatwedo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2.5rem;
    background-color: #141414;
    color: white;

    .whatwedo-btn {
        background-color: #3a3a3a;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 36px;
        margin-bottom: 20px;
        text-align: left;
        width: -webkit-fill-available;
    }

    .whatwedo-wrapper {
        display: flex;
        width: -webkit-fill-available;
        justify-content: space-evenly;
        gap: 2rem;
    }

    .whatwedo {
        border-radius: 10px;
        position: relative;
        text-align: left;
        width: 320px;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            border-radius: 10px;
            transition: filter 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        }

        &:hover img {
            filter: brightness(0.5);
            box-shadow: 0 6px 15px rgba(255, 255, 255, 0.7);
        }

        h3 {
            font-size: 22px;
            position: absolute;
            background: #00000066;
            width: -webkit-fill-available;
            bottom: 0;
            padding: 15px;
            text-align: center;
        }
    }

    .controls {
        display: flex;
        width: -webkit-fill-available;
        margin-top: 20px;
        margin-left: 20px;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .prev,
        .next {
            background-color: #00000099;
            backdrop-filter: blur(4px);
            border: none;
            border-radius: 50%;
            padding: 7px;
            color: white;
            font-size: 20px;
            width: 35px;
            height: 35px;
            cursor: pointer;
            transition: transform 0.3s;

            &:hover {
                transform: scale(1.1);
            }

            &:disabled {
                background-color: #4e4e4e;
                cursor: not-allowed;
                box-shadow: none;
                transform: none;
            }
        }

    }
}

@media screen and (max-width: 1220px) {
    .whatwedo-container {
        h2 {
            font-size: 28px;
        }

        .whatwedo {
            width: 270px;

            img {
                height: 250px;
            }

            h3 {
                font-size: 22px;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .whatwedo-container {
        .whatwedo-wrapper {
            justify-content: center;
        }

        .whatwedo {
            width: 270px !important;

            img {
                height: 300px;
            }

            h3 {
                font-size: 22px !important;
            }
        }
    }
}

@media screen and (max-width: 490px) {
    .whatwedo-container {
        padding: 0.5rem;
      
    }
    .whatwedo-container {
       .whatwedo-btn{
        margin-left: 12px;
       }
        h2 {
            font-size: 24px !important;
            padding-left: 12px;
        }

        .controls {
            margin: 0 !important;
            .next{
               
                    margin-left: -50px;
                
            }
        }

        .whatwedo {
            width: 220px !important;

            img {
                height: 300px;
        position: relative;
        left: -20px;
        width: 212px;
            }

            h3 {
                font-size: 22px !important;
                margin-left: -23px;
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .whatwedo-container {
        padding: 0.5rem;
    }
    .whatwedo-container .controls .next {
        margin-left: -50px;
    }
    .whatwedo-container .whatwedo img {
        height: 300px;
        position: relative;
        left: -20px;
        width: 212px;
    }
}