.whoweare-page {
    width: 100%;
    height: 750px;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("../../Assets/WhoWeAre/Team-Work.jpg");

    background-repeat: no-repeat;
    background-size: cover;
    // background-position: bottom;
    background-position: center;
    margin: auto;
    position: relative;

    .whoweare-page-text {
        color: #FFFFFF;
        color: #FFFFFF;
        position: relative;
        top: 65%;
        // padding-left: 10%;

        h2 {
            font-size: 40px;
            text-transform: uppercase;

            span {
                color: #EE781C;
                font-weight: 600;
            }

        }

        p {
            font-size: 24px;
            line-height: 1.6;
            width: 60%;
            padding: 1rem 0;
        }
    }
}

@media screen and (max-width: 1440px) {
    .whoweare-page {
        height: 600px;

        .whoweare-page-text {
            top: 50%;
            padding: 0 5%;

            h2 {
                font-size: 36px;
                text-wrap: pretty;
            }
        }
    }
}

@media screen and (max-width: 867px) {
    .whoweare-page {
        height: 400px;

        .whoweare-page-text {
            top: 44%;
            padding-left: 0;
            padding: 0 2rem;
            text-align: center;

            h2 {
                font-size: 18px;
            }

            p {
                font-size: 22px;
                width: 100%;
            }
        }
    }

}

@media screen and (max-width: 320px) {
    .whoweare-page {
        height: 350px;

        .whoweare-page-text {
            text-align: left;
            top: 50%;

            h2 {
                font-size: 16px;
                text-align: center;
            }

            p {
                display: none;
            }
        }
    }

}