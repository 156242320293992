.blog-view {
    width: 100%;
    background-color: #131313;
    color: #FFFFFF;

    .blog-view-img {
        width: 100%;
        height: auto;
        max-height: 600px;
        object-fit: cover;
        opacity: 0.8;
    }

    .blog-view-heading {
        position: relative;
        top: -122px;
        margin-left: 3rem;
        color: #FFFFFF;
        background-color: #0000006b;
        width: fit-content;
        padding: 1rem;

        h2 {
            font-size: 30px;
        }

        p {
            font-size: 24px;
            margin-top: 0.5rem;
            color: #808080;
        }
    }

    .blog-view-intro {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 3rem;

        .blog-view-card {
            background-color: #262626;
            border: 2px solid black;
            border-radius: 10px;
            padding: 2.5rem;
            width: 50%;
            line-height: 1.6;

            .blog-view-intro-title,
            .blog-view-intro-description {
                font-size: 20px;
                padding: 1rem;

            }

            .blog-view-body {
                p {
                    font-size: 20px;
                    margin-bottom: 0.2rem;
                    padding: 0.5rem 1rem;

                    strong {
                        color: #EE781C;
                    }

                }

                ul {
                    padding-left: 3rem;
                }

                h3 {
                    padding: 0 1rem;
                }
            }
        }

        .blog-share {
            display: flex;
            justify-content: space-between;
            padding: 3rem 0 0;

            .blog-share-icon {
                padding: 2rem 2rem 0;
                list-style: none;
                font-size: 30px;
                color: #FFFFFF;
                cursor: pointer;

                >div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1rem;
                    border-radius: 20%;
                    background-color: #262626;
                    background: linear-gradient(140.37deg, rgba(255, 255, 255, 0.1) 13.58%, rgba(255, 255, 255, 0) 87.25%);
                    transition: all 0.3s ease-in-out;
                    margin-bottom: 1.5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        background-color: #EE781C;
                    }
                }
            }
        }

    }
}

.blog-subscribe {
    background-color: #131313;
    padding: 2rem;

    .blog-subscribe-section {
        width: 100%;
        height: 355px;
        background: url("../../Assets/Blog/Typing-Laptop.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: auto;
        position: relative;
    }

    .blog-subscribe-text {
        display: grid;
        justify-content: center;
        justify-items: center;
        align-content: center;
        height: -webkit-fill-available;
        color: #FFFFFF;

        h2 {
            font-size: 40px;
            margin-bottom: 20px;
        }

        .text-box {
            width: 100%;
            margin-bottom: 20px;
            border: 2px solid #EE781C !important;
            background: none;
            color: #b8b8b8;
            padding: 1rem;
            border-radius: 10px;

            &:focus {
                outline: none !important;
                border: 2px solid #EE781C !important;
            }

            &::placeholder {
                color: #dad6d6;
                font-size: 18px;
            }

            &:hover {
                border: 2px solid #EE781C !important;
            }

            &:enabled:focus {
                border: 2px solid #EE781C !important;
                box-shadow: none !important;
            }
        }

        .subscribe-btn {
            padding: 1rem 2rem;
            gap: 10px;
            background: #EE781C;
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 18px !important;
            font-weight: 500 !important;
            border: none;

            &:focus {
                box-shadow: none !important;
            }

            &:hover {
                background: #262626;
                border: 2px solid #EE781C !important;
            }
        }
    }
}

@media screen and (max-width:1100px) {
    .blog-view {

        .blog-view-img {
            max-height: 500px;
        }

        .blog-view-heading {

            h2 {
                font-size: 28px;
            }

            p {
                font-size: 20px;
            }
        }

        .blog-view-intro {

            .blog-view-card {
                width: 70%;
            }

            .blog-share {
                padding: 3rem 1rem 0;
            }
        }
    }


    .blog-subscribe {
        .blog-subscribe-text {

            h2 {
                font-size: 30px;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .blog-view {

        .blog-view-intro {
            display: grid;
            justify-items: center;
            padding-bottom: 0;

            .blog-view-card {
                width: 75%;
                padding: 1.5rem;
                margin-top: -3rem;
            }

            .blog-share {
                padding: 0;
                margin: 3rem;
                width: 100%;
                justify-content: center;

                .blog-share-icon {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 70%;
                    padding: 1rem;

                    >div:last-child {
                        margin-bottom: auto;
                    }
                }
            }

        }
    }

    .blog-subscribe {
        .blog-subscribe-text {

            h2 {
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 490px) {
    .blog-view {
        .blog-view-heading {
            margin-left: 0;

            h2 {
                font-size: 26px;
            }

            p {
                font-size: 18px;
            }
        }

        .blog-view-intro {
            .blog-view-card {
                width: 90%;
                margin-top: -4rem;

                .blog-view-intro-description {
                    padding: 0.5rem 0;
                }

                .blog-view-body p {
                    font-size: 18px;
                    padding: 0.5rem 0;
                }
            }

            .blog-share {
                margin: 0;

                .blog-share-icon {
                    width: 90%;
                    padding: 2rem 0;
                }
            }
        }
    }

    .blog-subscribe .blog-subscribe-text {
        padding: 1rem;
        text-align: center;

        h2 {
            font-size: 26px;
        }
    }
}