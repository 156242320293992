.founder-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #181818;
    color: white;
    padding: 8rem 2rem;
    position: relative;

    .founder-section {

        display: flex;
        align-items: center;

        .founder-details {
            max-width: 50%;

            .role-tag {
                background-color: #333;
                padding: 0.3rem 0.6rem;
                border-radius: 4px;
                color: #ccc;
                font-size: 0.9rem;
            }

            .founder-name {
                font-size: 2rem;
                margin: 1rem 0;
            }

            .founder-bio {
                font-size: 1.2rem;
                line-height: 1.6;
                margin-bottom: 1.5rem;
                color: #A8A8A8;
            }

            .social-icons-sec {
                a {
                    color: #ff6b35;
                    font-size: 1.5rem;
                    margin-right: 1rem;
                    transition: color 0.3s ease;

                    &:hover {
                        color: #ffffff;
                    }

                    i {
                        transition: transform 0.3s ease;
                    }

                    &:hover i {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .image-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
            height: 300px;
            border-radius: 8px;
            transition: opacity 1s ease-in-out, transform 1s ease-in-out;
        }

        .current-image {
            z-index: 2;
            opacity: 1;
            transform: scale(1.2);
        }

        .upcoming-image {
            z-index: 1;
            opacity: 0.5;
            position: relative;
            right: 18%;
        }
    }
}

@media screen and (max-width: 1440px) {
    .founder-container .image-container {
        .current-image {
            position: relative;
            right: -6%;
        }

        .upcoming-image {
            position: relative;
            right: 12%;
        }
    }
}

@media screen and (max-width: 1200px) {
    .founder-container .image-container {
        img {
            height: 260px;
        }

        .current-image {
            right: -19%;
        }

        .upcoming-image {
            right: 10%;
        }
    }
}

@media screen and (max-width: 964px) {
    .founder-container {
        padding: 4rem 2rem;

        .founder-section {
            flex-direction: column;
            text-align: center;

            .founder-details {
                max-width: 100%;

                .founder-name {
                    font-size: 1.6rem;
                }

                .founder-bio {
                    font-size: 20px;
                }
            }
        }

        .image-container {
            justify-content: center;
            padding: 2rem 0;

            .current-image {
                transform: scale(1);
                right: auto;
            }

            .upcoming-image {
                transform: scale(1);
                display: none;
                right: auto;
            }
        }
    }
}