.blog-content-section {

    .blog-card-section {
        width: 100%;
        text-align: left;
        background: #131313;

        h2 {
            font-size: 40px;
            color: rgb(255, 255, 255);
            padding: 2rem 0 0;
        }

        .blogs-heading {
            font-size: 24px;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            i {
                font-size: 10px;
                color: #FFFFFF;
                padding: 8px;
                border: 2px solid #EE781C;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .pagination{
            color: white;
            
        }

        .p-paginator {
            color: #FFFFFF !important;
            background: transparent !important;
        }

        .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
            background: #EE781C;
            border-color: #EE781C;
            color:#FFFFFF;
            margin: 0 5px;
        }
        .p-paginator-page, .p-paginator-next, .p-paginator-last, .p-paginator-first, .p-paginator-prev, .p-paginator-current{
            color: white;
            margin: 0 5px;
        }
 
        .p-link:focus {
            box-shadow: 0 0 0 0.2rem #f8f8f8;
        }
    }
}

.card-content {
    flex-wrap: wrap;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    // padding: 2rem 5rem;

    .p-card .p-card-body {
        padding: 0 !important;
        height: -webkit-fill-available;
    }

    .new {
        height: 400px;
        // width: 400px;
        border-radius: 15px;
        background: #232323;
        box-shadow: 0 -1px 7px 0px rgb(190 175 175 / 81%);

        .blog-img {
            display: flex;
            justify-content: center;

            img {
                width: 95%;
                border-radius: 12px;
            }
        }

        .blog-text {
            margin: 0 1rem;

            span {
                color: #ababab;

            }
        }
    }

    .p-card .p-card-content {
        padding: 0.5rem 0 1rem 0;
        height: -webkit-fill-available;
        display: grid;
    }

}

.blog-content-section .gold-plan,
.blog-content-section .platinum-plan {
    min-height: 230px;
    display: grid;
    align-items: center;
}

.services-button {
    display: flex;
    justify-content: center;
    align-items: center;
}


.blog-content-section h2.related-section-heading {
    font-size: 32px;
    color: #FFFFFF;
    padding: 2rem 0 0 3rem;
}


@media screen and (max-width: 1240px) {
    .blog-content-section {
        .blog-card-section {
            h2 {
                font-size: 30px;
            }

            .card-content .new {
                height: 320px;

                .blog-text {
                    margin: 0 1rem;
                }
            }

            .blogs-heading {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .blog-content-section {
        .card-content {
            display: grid !important;
        }

        .card-content .new .blog-img img {
            width: 80%;
            border-radius: 12px;
            height: 214px;
        }

        .col-section {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .blog-content-section {
        .card-content {
            padding: 2rem 0;

            .new {
                height: 378px !important;

                .blog-img {
                    align-items: center;

                    img {
                        width: 80%;
                        border-radius: 12px;
                        height: 240px;
                    }
                }
            }
        }

        .col-section {
            width: 100%;
        }
    }

    .blog-content-section h2.related-section-heading {
        font-size: 28px;
        padding: 2rem 0 0 2rem;
    }
}

@media (max-width: 490px) {
    .blog-content-section {

        .blog-card-section h2 {
            font-size: 28px;
            padding: 0;
            text-align: center;
        }

        .card-content {
            padding: 2rem 0;

            .new {
                height: -webkit-fill-available !important;

                .blog-img {
                    align-items: flex-start;

                    img {
                        width: 95%;
                        border-radius: 12px;
                        // height: fit-content;
                        height: auto;
                    }
                }

                .blog-text {
                    span {
                        font-size: 14px;
                    }

                    .blogs-heading {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .blog-content-section h2.related-section-heading {
        font-size: 26px;
    }
}