* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* font-family: "Source Sans Pro", sans-serif; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   /* cursor: none; */

}

.p-inputtext {
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  border-radius: 4px;
  border: none;
  border: 2px solid #ffffff;
  background: white;
  color: #b5b5b5;

  &:focus-visible {
    outline: none;
    border: 2px solid transparent !important;
    box-shadow: none !important;
  }
}

.p-input-icon-right i {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem !important;
  right: 5%;
  cursor: pointer;
}

.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 10px;
  z-index: 2;
}

.container1 {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 10px;
  z-index: 2;
}

.container2 {
  width: 1100px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  padding: 20px 30px;
  z-index: 2;
}


@media (min-width: 320px) and (max-width: 767px) {
  .container {
    width: 100%;
    padding: 20px;
  }

  .container1 {
    width: 100%;
    /* padding: 20px; */
  }

  .container2 {
    width: 100%;
    /* padding: 20px; */
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1140px;
    padding: 20px;
  }

  .container1 {
    width: 1140px;
  }

  .container2 {
    width: 900px;
  }
}

@media (min-width:992px) and (max-width:1199px) {

  .container2 {
    width: 800px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .container2 {
    width: 700px;
  }
}

@media (min-width: 1400px) {
  .container {
    /* width: 1370px; */
    padding: 20px;
  }

  .container1 {
    width: 1350px;
  }

  .container2 {
    width: 1000px;
  }
}