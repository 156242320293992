.our-services {
    background-color: #121212;
    padding: 3rem 2.5rem;
    color: white;

    .service-heading {
        margin: 2rem 0;

        .service-btn {
            background-color: #3a3a3a;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            margin-bottom: 2rem;
        }

        h2 {
            font-size: 32px;
        }
    }

    .service-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .service-card {
            background-color: #1d1d1d;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: background-color 0.3s ease-in-out;
            // cursor: pointer;
            margin-bottom: 2rem;

            &:hover {
                background-color: #bfbebe6e;
            }

            .service-icon {
                font-size: 2.5rem;
                color: #ff6700;
                margin-right: 20px;

                svg {
                    display: block;
                }
            }

            p {
                font-size: 1.2rem;
                margin: 0;
                flex-grow: 1;
                text-align: left;
                color: white;
            }

            .service-arrow {
                font-size: 1.5rem;
                color: #ff6700;
            }
        }
    }

    @media (max-width: 864px) {
        padding: 2rem;

        .service-cards {
            grid-template-columns: repeat(2, 1fr);

            .service-card {
                .service-icon {
                    font-size: 2rem !important;
                }
            }
        }

        h2 {
            font-size: 2rem !important;
        }
    }


    @media (max-width: 590px) {
        .service-cards {
            grid-template-columns: 1fr;

            .service-card {
                margin-bottom: 0;
            }
        }

        h2 {
            font-size: 1.5rem !important;
        }
    }
}