.footer-section-black {
    background-color: #000000;

    .footer-links {
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(4, auto);

        .logosection {
            display: grid;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            .footer-icons {
                display: flex;
                justify-content: space-between;

                .socialmedia-icon a {
                    color: #EE781C;
                    width: 56px;
                    font-size: 25px;
                    display: flex;
                }
            }
        }

        .quick-links {
            h4 {
                color: #EE781C;
                margin-bottom: 10px;
            }

            ul {
                list-style: none;
            }

            ul li {
                padding: 8px 0;
                color: #C7C7C7;
            }

            a {
                text-decoration: none;
                color: #C7C7C7;
            }

            p {
                color: #C7C7C7;
            }
        }
    }

    .p-divider .p-divider-content {
        background-color: #848484;
        height: 1px;
        width: 100%;
    }

    .footer-sec {
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .policy-section {
            display: flex;
            align-items: center;
            // margin: 0 80px;
            justify-content: center;

            a {
                margin-right: 20px;
                color: #747474;
                text-decoration: none;
                transition: color 0.2s ease;
            }

            a:nth-child(2):hover,
            a:nth-child(3):hover {
                color: white;
            }
        }

        .logo-sec {
            display: flex;
            align-items: center;
            // margin: 0 70px;
            justify-content: center;

            img {
                width: 120px !important;
                filter: invert(0.6);
            }
        }
    }
}


@media screen and (max-width: 992px) {
    .footer-section-black {

        .footer-links {
            grid-template-columns: repeat(2, auto);
            // justify-items: stretch;

            .logosection {
                display: grid;
                justify-content: start;
                margin-left: 20px;
                margin-bottom: 20px;
            }
        }

        .footer-sec {
            display: grid;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .policy-section {
                display: grid;
                justify-content: center;

                a {
                    text-align: center;
                    padding-bottom: 15px;
                    margin-right: 0;

                    &:last-child {
                        padding-bottom: 10px;
                    }
                }

            }

            .logo-sec {
                justify-content: center;
                margin: auto;

                img {
                    width: 100px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .footer-section-black {

        .p-6 {
            padding: 1.5rem !important;
        }

        .footer-links {
            grid-template-columns: repeat(3, auto);

            .logosection {
                margin-left: 5px;
            }

            .col,
            .m-3 {
                margin: 1rem 0px !important;
            }
            // .footer-news{
            //     position: relative;
            //     left: 10%;
            // }
        }

        .footer-sec {
            display: grid;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .policy-section {
                display: grid;
                justify-content: center;

                a {
                    text-align: center;
                    padding-bottom: 15px;
                    margin-right: 0;

                    &:last-child {
                        padding-bottom: 10px;
                    }
                }

            }

            .logo-sec {
                justify-content: center;
                margin: auto;

                img {
                    width: 100px !important;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .footer-section-black {

        .footer-links {
            grid-template-columns: repeat(2, auto);
            justify-items: center;

            .logosection {
                justify-items: center;
                margin-left: 0;
                display: flex;
                flex-direction: column-reverse;

                .footer-icons {
                    padding-top: 25px;
                }

                .footer-icons .socialmedia-icon a {
                    justify-content: center;
                }

                img {
                    padding-top: 20px;
                }
            }

            .quick-links {
                text-align: center;
            }
        }

        .footer-sec {
            display: flex !important;
            flex-direction: column-reverse;

            .policy-section {
                display: grid;
                justify-content: center;

                a {
                    text-align: center;
                    padding-bottom: 15px;
                    margin-right: 0;

                    &:last-child {
                        padding-bottom: 30px;
                    }
                }

            }

            .logo-sec {
                display: grid;
                justify-content: center;
                grid-template-columns: repeat(2, auto);

                img {
                    width: 100px !important;
                }
            }
        }
    }
}
@media screen and (max-width:490px) {
    .footer-section-black {

        .footer-links {
            grid-template-columns: repeat(1, auto);
            justify-items: center;

            .logosection {
                justify-items: center;
                margin-left: 0;
                display: flex;
                flex-direction: column-reverse;

                .footer-icons {
                    padding-top: 25px;
                }

                .footer-icons .socialmedia-icon a {
                    justify-content: center;
                }

                img {
                    padding-top: 20px;
                }
            }

            .quick-links {
                text-align: center;
            }
        }

        .footer-sec {
            display: flex !important;
            flex-direction: column-reverse;

            .policy-section {
                display: grid;
                justify-content: center;

                a {
                    text-align: center;
                    padding-bottom: 15px;
                    margin-right: 0;

                    &:last-child {
                        padding-bottom: 30px;
                    }
                }

            }

            .logo-sec {
                display: grid;
                justify-content: center;
                grid-template-columns: repeat(1, auto);

                img {
                    width: 100px !important;
                }
            }
        }
    }
}